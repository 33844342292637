export const routes = (parentName) => {
  return [
    {
      path: "calender",
      component: () => import("./Index.vue"),
      children: [
        {
          path: "",
          name: `${parentName}-Calender`,
          component: () => import("./List.vue"),
          props: (route) => ({
            step: route.query.step,
          }),
        },
        {
          path: "new",
          name: `${parentName}-Calender-New`,
          component: () => import("./Upsert.vue"),
        },
        {
          path: ":id",
          name: parentName + "-" + "Calender-View",
          component: () => import("./View.vue"),
        },
        {
          path: ":id/edit",
          name: parentName + "-" + "Calender-Edit",
          component: () => import("./Upsert.vue"),
        },
      ],
    },
  ];
};
