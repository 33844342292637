import { createRouter, createWebHistory } from "vue-router";
import LayoutPublic from "../layouts/Public.vue";
import {
  routes as managerRoutes,
  middleware as managerMiddleware,
} from "@/views/manager/Routes";
import {
  routes as userRoutes,
  middleware as userMiddleware,
} from "@/views/user/Routes";

import {
  routes as operatorRoutes,
  middleware as operatorMiddleware,
} from "@/views/operator/Routes";

import store from "../store";

const routes = [
  {
    path: "/",
    name: "Public",
    component: LayoutPublic,
    children: [
      {
        path: "",
        name: "PublicHome",
        component: () => import("../views/public/Home"),
        //redirect: (to) => {
        //return '/user/';
        //},
      },
    ],
  },

  {
    path: "/verify",
    name: "verify",
    component: LayoutPublic,
    children: [
      {
        path: "",
        name: "PublicVerify",
        component: () => import("../views/public/Verify"),
      },
    ],
  },

  ...managerRoutes,
  //...operatorRoutes,
  ...userRoutes,

  ...operatorRoutes,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (
    to.matched.some((record) => {
      /**
       * خیلی مهم:
       * میدلور هر لایه حساب کاربری باید به طوری جداگانه فراخوانی شود
       * و در زیر قرار بگیرد
       *
       **/
      console.log(record);

      managerMiddleware(record, next, store.state.managerIsAuthenticated);
      //operatorMiddleware(record, next, store.state.operatorIsAuthenticated);
      userMiddleware(record, next, store.state.userIsAuthenticated);
      operatorMiddleware(record, next, store.state.operatorIsAuthenticated);
    })
    /* eslint-disable */
  ) {
  } else {
    next(); // make sure to always call next()!
  }
});

export default router;
