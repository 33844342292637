import { createApp } from "vue";
import axios from "axios";
import App from "./App.vue";
//import io from 'socket.io-client';
import router from "./router";
import store from "./store";
import "@fortawesome/fontawesome-free/css/all.css";

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

//import 'vazirmatn/Vazirmatn-font-face.css';
//import 'vazirmatn/misc/Farsi-Digits/Vazirmatn-FD-font-face.css';

// +++++++ multilanguage     +++++++ +++++++ +++++++ +++++++ +++++++ +++++++ +++++++ //
import i18n from "./i18n";
// ####### END multilanguage ####### ####### ####### ####### ####### ####### ####### //

// +++++++ Essential Components     +++++++ +++++++ +++++++ +++++++ +++++++ +++++++ +++++++ //
import PrRouterView from "@/components/custom/PrRouterView.vue";
import ButtonLoading from "@/components/custom/ButtonLoading.vue";
import PrDebugConsole from "@/components/custom/PrDebugConsole.vue";
import PrPreLoaderFull from "@/components/custom/PrPreLoaderFull.vue";

import apisPath from "@/utils/apisPath";

import CKEditor from "@ckeditor/ckeditor5-vue";
// ####### END Essential Components ####### ####### ####### ####### ####### ####### ####### //

// +++++++ API base url +++++++ +++++++ +++++++ +++++++ +++++++ +++++++ +++++++ //
//store.commit('readApiBaseUrl');
//axios.defaults.baseURL = 'http://' + store.state.apiBaseUrl + ':3000';
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
// ############################################################################ //

const app = createApp(App);

//app.config.globalProperties.$soketio = io('localhost:3000');

app
  .use(i18n)
  .use(store)
  .use(Toast, {
    transition: "Vue-Toastification__fade",
    maxToasts: 20,
    newestOnTop: true,
  })
  .component("PrRouterView", PrRouterView)
  .component("ButtonLoading", ButtonLoading)
  .component("PrDebugConsole", PrDebugConsole)
  .component("PrPreLoaderFull", PrPreLoaderFull)
  .use(router)
  .use(CKEditor)
  .mount("#app");
