export const routes = (parentName) => {
  return [
    {
      path: "qsciencemap",
      component: () => import("./Index.vue"),
      children: [
        {
          path: "",
          name: `${parentName}-Qsciencemap`,
          component: () => import("./List.vue"),
          props: (route) => ({
            step: route.query.step,
          }),
        },

        {
          path: "qsciencemap",
          name: `${parentName}-Qsciencemap-Gateway`,
          component: () => import("./List.vue"),
          props: (route) => ({
            step: route.query.step,
          }),
        },
        {
          path: "new",
          name: `${parentName}-Qsciencemap-New`,
          component: () => import("./Upsert.vue"),
        },

        {
          path: "all/news",
          name: `${parentName}-Qsciencemap-News-List`,
          component: () => import("./ListAll.vue"),
        },
        {
          path: ":id",
          name: parentName + "-" + "Qsciencemap-View",
          component: () => import("./View.vue"),
        },
        {
          path: ":id/edit",
          name: parentName + "-" + "Qsciencemap-Edit",
          component: () => import("./Upsert.vue"),
        },
      ],
    },
  ];
};
