import store from "@/store";
import layout from "@/layouts/PanelManager.vue";

import { routes as blogRoutes } from "./blog/Routes";
import { routes as operatorsRoutes } from "./operators/Routes";
import { routes as managersRoutes } from "./managers/Routes";
import { routes as eventsRoutes } from "./events/Routes";
import { routes as pagesRoutes } from "./pages/Routes";
import { routes as authRoutes } from "./auth/Routes";
import { routes as settingRoutes } from "./setting/Routes";
import { routes as languagesRoutes } from "./Languages/Routes";
import { routes as preferencesRoutes } from "./preferences/Routes";
import { routes as dossiersRoutes } from "./dossiers/Routes";
import { routes as analyticsRoutes } from "./analytics/Routes";
import { routes as monitoringRoutes } from "./monitoring/Routes";
import { routes as historyRoutes } from "./history/Routes";
import { routes as organizationRoutes } from "./organization/Routes";
import { routes as jobsRoutes } from "./jobs/Routes";
import { routes as zonesRoutes } from "./zones/Routes";
import { routes as devicesRoutes } from "./devices/Routes";
import { routes as routesRandomNumberRequests } from "./randomNumberRequests/Routes";
import { routes as routesUsers } from "./users/Routes";
import { routes as routesTransactions } from "./transactions/Routes";
import { routes as podcastRoutes } from "./podcast/Routes";
import { routes as routesVideo } from "./video/Routes";
import { routes as routesWriter } from "./writers/Routes";
import { routes as routesGames } from "./games/Routes";
import { routes as routesWebinar } from "./webinar/Routes";
import { routes as routesUploadcenter } from "./uploadcenter/Routes";
import { routes as routesDiscounts } from "./discounts/Routes";
import { routes as routesQsciencemap } from "./qsciencemap/Routes";
import { routes as routesChats } from "./chats/Routes";
import { routes as routesEvenRegistered } from "./eventRegistered/Routes";
import { routes as routesTimeLine } from "./timeLine/Routes";
import { routes as routesCalender } from "./calender/Routes";
const groupName =
  store.state
    .managerRoutesGroupName; /* نام گروه کابری برای مدیریت داشبرد یا پنل مورد نظر */
const pathRoot =
  store.state
    .managerRoutesPathRoot; /* مسیری ریشه‌ای که برای گروه کاربری در نظر گرفته‌ایم */
const parentName =
  store.state
    .managerRoutesParentName; /* نام اصلی برای زیر مسیر‌های مدیریت داشبرد یا پنل مورد نظر */
// ------- Routes -------- -------- -------- -------- -------- -------- -------- //
export const routes = [
  {
    name: parentName,
    path: pathRoot,
    component: layout,
    meta: {
      requiresAuth: true,
      group: groupName,
    },
    children: [
      ...operatorsRoutes(parentName),
      ...managersRoutes(parentName),
      ...eventsRoutes(parentName),
      ...zonesRoutes(parentName),
      ...blogRoutes(parentName),
      ...podcastRoutes(parentName),
      ...pagesRoutes(parentName),
      ...settingRoutes(parentName),
      ...languagesRoutes(parentName),
      ...preferencesRoutes(parentName),
      ...dossiersRoutes(parentName),
      ...analyticsRoutes(parentName),
      ...monitoringRoutes(parentName),
      ...historyRoutes(parentName),
      ...organizationRoutes(parentName),
      ...jobsRoutes(parentName),
      ...devicesRoutes(parentName),
      ...routesRandomNumberRequests(parentName),
      ...routesUsers(parentName),
      ...routesTransactions(parentName),
      ...routesVideo(parentName),
      ...routesWriter(parentName),
      ...routesGames(parentName),
      ...routesWebinar(parentName),
      ...routesUploadcenter(parentName),
      ...routesDiscounts(parentName),
      ...routesQsciencemap(parentName),
      ...routesChats(parentName),
      ...routesEvenRegistered(parentName),
      ...routesCalender(parentName),
      ...routesTimeLine(parentName),
      {
        path: "",
        redirect: {
          name: parentName + "-" + "Home",
        },
      },

      {
        path: "home",
        name: parentName + "-" + "Home",
        component: () => import("./Home.vue"),
      },

      {
        path: "message-list",
        name: parentName + "-" + "MessageList",
        component: () => import("./MessageList.vue"),
      },

      // -------- Language ------- ------- ------- ------- ------- ------- ------- //
      //  {
      //   path: "languages",
      //   name: parentName + "-" + "Languages",
      //   component: () => import("./Languages.vue"),
      // },
      // -------- END Language ------- ------- ------- ------- ------- ------- ------- //

      {
        path: "profile",
        name: parentName + "-" + "Profile",
        component: () => import("./Profile.vue"),
      },
    ],
  },

  // ####### Auth ####### ####### ####### ####### ####### ####### ####### ####### //
  /**
   * مهم:
   * چون لاگین و رجیستر از لایه پیشفرض گروه مدیریت پیروی نمی‌کنند باید خارج از فضای چیلدرن نوشته شود
   */

  ...authRoutes(parentName, pathRoot, groupName),
  // ####### END Auth ####### ####### ####### ####### ####### ####### ####### ####### //
];

// ------- END Routes -------- -------- -------- -------- -------- -------- -------- //

export const middleware = (record, next, checkAuth) => {
  // ------- Check Group ------- ------- ------- ------- ------- ------- ------- //
  if (record.meta.group === groupName) {
    // ------- Check Auth ------- ------- ------- ------- ------- ------- ------- //
    if (record.meta.requiresAuth) {
      console.log("SALAMA!");
      //const redirectPath = window.location.pathname; // برگشت به آدرس این صفحه
      if (checkAuth) {
        next();
      } else {
        next({
          name: `${parentName}-Login`,
          query: { redirect: window.location.pathname },
        });
      }
    }
    // ####### END Check Auth ####### ####### ####### ####### ####### ####### ####### //

    // ------- Check Auth Reverse ------- ------- ------- ------- ------- ------- ------- //
    else if (record.meta.requiresAuthReverse) {
      if (!checkAuth) {
        next();
      } else {
        next({
          name: `${parentName}-Login`,
        });
      }
    }
    // ####### END Check Auth  Reverse ####### ####### ####### ####### ####### ####### #######  //
  }
  // ####### END Check Group ####### ####### ####### ####### ####### ####### ####### //
};
