export const routes = (parentName) => {
  return [
    {
      path: "uploadcenter",
      component: () => import("./Index.vue"),
      children: [
        {
          path: "",
          name: `${parentName}-Uploadcenter`,
          component: () => import("./List.vue"),
        },
      ],
    },
  ];
};
